/**
 * @generated SignedSource<<5b43ea1989fb976f139fbb1cd5e66cbe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type hooksTrackCurrentItemBase$data = {
  readonly __typename: string;
  readonly " $fragmentSpreads": FragmentRefs<"atomsCurrentArticleAtomBase" | "atomsCurrentNotificationSectionAtomBase" | "atomsCurrentStyleCardCarouselWidgetSectionsAtomBase" | "atomsCurrentTakeoverAdSectionsAtomBase">;
  readonly " $fragmentType": "hooksTrackCurrentItemBase";
};
export type hooksTrackCurrentItemBase$key = {
  readonly " $data"?: hooksTrackCurrentItemBase$data;
  readonly " $fragmentSpreads": FragmentRefs<"hooksTrackCurrentItemBase">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "hooksTrackCurrentItemBase"
};

(node as any).hash = "aa110650cc26398a776c5311e5e4eccb";

export default node;
