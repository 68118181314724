import { useMemo } from "react";

import { Edition, useEditionValue } from "~/lib/edition";

export const useGetHomeAsPath = () => {
  const edition = useEditionValue({
    initializeWithValue: false,
  });
  return useMemo(() => {
    if (edition === Edition.International) return "/";
    return `/${edition}`;
  }, [edition]);
};
