import { getGAClientIdFromGTM } from "@product/scmp-sdk";

import { sendGTMTracking } from "~/components/tracking/google-tag-manager/apis";
import { config } from "~/data";

import type { Event, UntypedEvent } from "./types";

export const getGoogleAnalyticsClientIdSync = () => {
  if (typeof ga === "undefined") return;
  const instance = ga?.getAll?.()?.[0];
  if (!instance) return;
  return instance.get("clientId") as string;
};

export const getGoogleAnalyticsClientId = () =>
  getGoogleAnalyticsClientIdSync() ?? getGAClientIdFromGTM(config.googleAnalytics.profileId);

export function sendGATracking<Untyped extends true | false = false>(
  event: Untyped extends true ? UntypedEvent : Event,
  options?: { untyped: Untyped },
) {
  sendGTMTracking({ _clear: true, event: "tracking", tracking: event }, options);
}
