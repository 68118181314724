import { useIntersectionObserver } from "@react-hookz/web";
import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";

import { whyRegisterHomepagePositionTriggerReferenceAtom } from "~/pages/home/atoms";

export const useHasWhyRegisterPPTOHomepagePositionIntersected = () => {
  const [
    hasWhyRegisterPPTOHomepagePositionIntersected,
    setHasWhyRegisterPPTOHomepagePositionIntersected,
  ] = useState(false);

  const whyRegisterHomepagePositionTriggerReference = useAtomValue(
    whyRegisterHomepagePositionTriggerReferenceAtom,
  );

  const observer = useIntersectionObserver(whyRegisterHomepagePositionTriggerReference);

  useEffect(() => {
    if (!observer?.isIntersecting || hasWhyRegisterPPTOHomepagePositionIntersected) return;
    setHasWhyRegisterPPTOHomepagePositionIntersected(true);
  }, [hasWhyRegisterPPTOHomepagePositionIntersected, observer?.isIntersecting]);

  return hasWhyRegisterPPTOHomepagePositionIntersected;
};
