import { useAtomValue, useSetAtom } from "jotai";
import { useCallback } from "react";
import { graphql, readInlineData } from "react-relay";

import type { hooksTrackCurrentItemBase$key } from "~/queries/__generated__/hooksTrackCurrentItemBase.graphql";
import type { hooksUseCurrentArticleIdsArticle$key } from "~/queries/__generated__/hooksUseCurrentArticleIdsArticle.graphql";

import { currentArticleAtom, currentItemAtom } from "./atoms";

export const useTrackCurrentItem = () => {
  const setCurrentContent = useSetAtom(currentItemAtom);

  const trackCurrentItem = useCallback(
    (item?: hooksTrackCurrentItemBase$key) => {
      if (!item) {
        setCurrentContent(null);
        return;
      }

      const data = readInlineData(
        graphql`
          fragment hooksTrackCurrentItemBase on Base
          @inline
          @argumentDefinitions(customContents: { type: "[CustomContentInput]", defaultValue: [] }) {
            __typename
            ...atomsCurrentArticleAtomBase @arguments(customContents: $customContents)
            ...atomsCurrentNotificationSectionAtomBase
            ...atomsCurrentTakeoverAdSectionsAtomBase
            ...atomsCurrentStyleCardCarouselWidgetSectionsAtomBase
          }
        `,
        item,
      );
      setCurrentContent(data);
    },
    [setCurrentContent],
  );

  return { trackCurrentItem };
};

export const useCurrentArticleIds = () => {
  const currentArticle = useAtomValue(currentArticleAtom);
  if (!currentArticle) return { currentArticleId: undefined, currentArticleUuid: undefined };

  const { entityId, entityUuid } = readInlineData<hooksUseCurrentArticleIdsArticle$key>(
    graphql`
      fragment hooksUseCurrentArticleIdsArticle on Article @inline {
        entityId
        entityUuid
      }
    `,
    currentArticle,
  );

  return { currentArticleId: entityId, currentArticleUuid: entityUuid };
};
