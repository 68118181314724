import { initializeApp } from "@firebase/app";
import type { FirebaseApp } from "@firebase/app";

import { config } from "~/data";

let app: FirebaseApp;

export const getFirebaseApp = () => {
  if (!app) {
    app = initializeApp({
      apiKey: config.firebase.apiKey,
      appId: config.firebase.appId,
      authDomain: config.firebase.authDomain,
      databaseURL: config.firebase.databaseURL,
      messagingSenderId: config.firebase.messagingSenderId,
      projectId: config.firebase.projectId,
      storageBucket: config.firebase.storageBucket,
    });
  }
  return app;
};
