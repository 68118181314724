export function sendRouteChangedOnePlusXEvent(visitUrl: string) {
  if (window.ope === undefined) return;
  // TODO: confirm with 1PlusX support team after full launch of generic article
  // to remove sending both values for comparability as this custom event is not documented
  window.ope("scmp", "send", "routeChange", {
    visitUrl,
    visiturl: visitUrl,
  });
}

export function sendOnePlusXEvent<Untyped extends true | false = false>(
  { type, ...event }: Untyped extends true ? UntypedEvent : Event,
  _options?: { untyped: Untyped },
) {
  if (window.ope === undefined) return;
  window.ope("scmp", "send", type, {
    ...event,
  });
}

export function sendVisitedOnePlusXEvent(visitUrl: string) {
  if (window.ope === undefined) return;
  window.ope("scmp", "sendVisit", {
    visitUrl,
  });
}

type UntypedEvent = {
  type: string;
} & Record<string, unknown>;

type Event =
  | PageLoadEvent
  | ContentInterestEvent
  | NewsletterSignUpEvent
  | FullReadArticleEvent
  | VideoPlaysEvent
  | ScmpAlerts
  | EditionPreferenceEvent
  | HomepagePreferenceEvent
  | PodcastListenerEvent
  | PodcastsEvent
  | PodcastPlayerEvent;

// Use anonymous to keep backward compatibility
export type UserType = "anonymous" | "Subscriber" | "Authenticated";
interface PageLoadEvent {
  cd32?: string | undefined;
  cd34?: string | undefined;
  type: "pageLoad";
  userType: UserType;
}

interface ContentInterestEvent {
  articleID: string;
  contentTopics: string;
  contentType: string;
  readability_school_level: string;
  sentiment_category: string;
  siteVerticals: string;
  type: "scmp-content-interests";
}
interface NewsletterSignUpEvent {
  newsletterSubscribe: string;
  type: "newsletter-sign-up";
}
interface FullReadArticleEvent {
  topic: string;
  type: "read-full-article";
}
interface VideoPlaysEvent {
  action: string;
  type: "video-plays";
  videoVertical: string;
}
interface ScmpAlerts {
  topic: string;
  type: "scmp-alerts";
}
interface EditionPreferenceEvent {
  edition: string;
  type: "edition-preference";
}
interface HomepagePreferenceEvent {
  action: string;
  type: "homepage-preference";
}
interface PodcastListenerEvent {
  episode: string;
  episodeId: string;
  section: string;
  type: "podcast-listener";
}
interface PodcastsEvent {
  section: string;
  type: "podcasts";
}
interface PodcastPlayerEvent {
  name: string;
  type: "podcast-player";
}
