import type { SdkFunction } from "@product/scmp-sdk";
import { useAtomValue } from "jotai";
import { useRouter } from "next/router";
import { useCallback, useMemo } from "react";
import type { Environment } from "react-relay";

import { useBookmark } from "~/components/content/content-bookmark/hooks";
import { useUserFollow } from "~/components/follow-button/hooks";
import { useLoginDialogStateHelper } from "~/components/login-dialog/hooks";
import { registerMixPanelParameters, trackMixPanel, useMixpanel } from "~/components/mixpanel";
import { gdprAtom } from "~/components/tracking/gdpr";
import { sendGATracking } from "~/components/tracking/google-analytics/apis";
import { sendGA4Tracking } from "~/components/tracking/google-analytics-4/apis";
import { sendGTMTracking } from "~/components/tracking/google-tag-manager/apis";
import { sendOnePlusXEvent } from "~/components/tracking/one-plus-x";
import { config, newsletter } from "~/data";
import { accountAtom } from "~/lib/account";
import { appAtom } from "~/lib/app/atoms";
import { useCurrentArticleIds } from "~/lib/current-item";
import { useEdition } from "~/lib/edition";
import { getFirebaseApp } from "~/lib/firebase";
import { useMyNews, useUserTypes } from "~/lib/hooks";
import { rosettaAtom } from "~/lib/rosetta";
import {
  useCurrentPageType,
  useGetHomeAsPath,
  useRouteHandler,
  useRouterReplaceWithSanitizedKeys,
} from "~/lib/router/hooks";

import { useHasWhyRegisterPPTOHomepagePositionIntersected } from "./has-why-register-ppto-homepage-position-intersected";

export const useScmpSdk = (environment: Environment) => {
  const { asPath, pathname, query } = useRouter();
  const replace = useRouterReplaceWithSanitizedKeys();
  const handleRoutePush_ = useRouteHandler();
  const { currentArticleUuid } = useCurrentArticleIds();

  const edition = useEdition();
  const currentPageType = useCurrentPageType();
  const { checkIsFollowed, handleFollowAction } = useUserFollow();
  const { checkIsBookmarked, handleBookmarkAction } = useBookmark();
  const { hasEnoughFollowedItems, initialHasEnoughFollowedItems } = useMyNews();
  const { openLoginDialog } = useLoginDialogStateHelper();
  const firebaseApp = useMemo(() => getFirebaseApp(), []);
  const mixpanel = useMixpanel();
  const { userTypes } = useUserTypes();
  const userTypeIds = userTypes.map(userType => userType.id);

  const { ipGeoLocation } = useAtomValue(appAtom);
  const { user } = useAtomValue(accountAtom);
  const asyncRosettaState = useAtomValue(rosettaAtom);
  const { isAccepted: hasAcceptedGDPR } = useAtomValue(gdprAtom);

  const hasIpAccess = asyncRosettaState?.result?.hasIpAccess ?? false;
  const isScmpSubscriber = asyncRosettaState?.result?.user?.isSCMPSubscriber ?? false;
  const isSubscriptionEnabled =
    asyncRosettaState?.result?.contentServiceUserInfo?.isSubscriptionEnabled ?? false;
  const isShowSubscription = !hasIpAccess && !isScmpSubscriber && isSubscriptionEnabled;

  const bookmarkCheck = useCallback<SdkFunction["bookmark"]["check"]>(
    payload => checkIsBookmarked(payload.entityId),
    [checkIsBookmarked],
  );
  const bookmarkUpdate = useCallback<SdkFunction["bookmark"]["update"]>(
    payload =>
      handleBookmarkAction(
        { action: payload.action, id: payload.entityId },
        { events: payload.trackings },
      ),
    [handleBookmarkAction],
  );
  const handleCheckIsFollowed = useCallback<SdkFunction["handleCheckIsFollowed"]>(
    payload => checkIsFollowed(payload.followType, payload.entityId),
    [checkIsFollowed],
  );
  const handleFollow = useCallback<SdkFunction["handleFollow"]>(
    payload => {
      handleFollowAction(payload.entityIds, payload.followType, payload.action, payload.callback);
    },
    [handleFollowAction],
  );

  const handleOpenLoginDialog = useCallback<SdkFunction["handleOpenLoginDialog"]>(
    ({ description, destination, ga4CustomParameter, title }) => {
      openLoginDialog({ description, destination, ga4CustomParameter, title });
    },
    [openLoginDialog],
  );
  const handleRegisterMixPanel = useCallback<SdkFunction["handleRegisterMixPanel"]>(
    payload => {
      if (!payload || !mixpanel) return;
      registerMixPanelParameters(mixpanel.result, payload);
    },
    [mixpanel],
  );
  const handleRemoveRouteQuery = useCallback<SdkFunction["handleRemoveRouteQuery"]>(
    async sanitizedKeys => {
      await replace(
        sanitizedKeys,
        { pathname, query },
        {
          shallow: true,
        },
      );
    },
    [pathname, query, replace],
  );
  const handleRoutePush = useCallback<SdkFunction["handleRoutePush"]>(
    async (url: string, extraOptions = {}) => {
      await handleRoutePush_(url, extraOptions);
    },
    [handleRoutePush_],
  );
  const handleTrackGA = useCallback<SdkFunction["handleTrackGA"]>(payload => {
    if (!payload) return;
    sendGATracking<true>(payload, { untyped: true });
  }, []);
  const handleTrackGA4 = useCallback<SdkFunction["handleTrackGA4"]>(payload => {
    if (!payload) return;
    sendGA4Tracking(payload, { untyped: true });
  }, []);
  const handleTrackGTM = useCallback<SdkFunction["handleTrackGTM"]>(payload => {
    if (!payload) return;
    sendGTMTracking(payload, { untyped: true });
  }, []);
  const handleTrackMixPanel = useCallback<SdkFunction["handleTrackMixPanel"]>(
    payload => {
      if (!payload || !mixpanel) return;
      trackMixPanel(mixpanel.result, payload, { untyped: true });
    },
    [mixpanel],
  );
  const handleTrackOnePlusX = useCallback<SdkFunction["handleTrackOnePlusX"]>(payload => {
    if (!payload) return;
    sendOnePlusXEvent(payload, { untyped: true });
  }, []);

  const homeAsPath = useGetHomeAsPath();

  const hasWhyRegisterPPTOHomepagePositionIntersected =
    useHasWhyRegisterPPTOHomepagePositionIntersected();

  const value = useMemo<SdkFunction>(
    () => ({
      bookmark: {
        check: bookmarkCheck,
        update: bookmarkUpdate,
      },
      config: {
        accounts: {
          authCallbackUrl: config.account.authCallbackUrl,
          host: config.account.scmpAccountHost,
          registerCallbackUrl: config.account.registerCallbackUrl,
        },
        corporateSubscriber: {
          host: config.corporateSubscriber.host,
        },
        environment: "dev",
        facebook: {
          appId: config.social.facebook.appId,
          likeUrl: config.social.facebook.likeUrl,
        },
        graphql: {
          apiKey: config.graphql.contentService.apiKey,
          host: config.graphql.contentService.host,
        },
        mixpanel: { token: config.account.mixPanelToken },
        newsletter: {
          globalImpact: newsletter.globalImpact.entityId,
          lunar: newsletter.lunar.entityId,
          postMag: newsletter.postMag.entityId,
          thisWeekInAsia: newsletter.thisWeekInAsia.entityId,
        },
        notification: {
          host: "",
        },
        readingHistory: {
          apiKey: config.graphql.contentService.apiKey,
          host: config.general.readingHistoryHost,
        },
        recaptcha: {
          siteKey: config.account.recaptchaSiteKey,
        },
        siteUrl: config.general.siteUrl,
        subscribe: {
          host: config.general.rosettaHost,
        },
        twitter: {
          accountName: config.social.twitter.scmp.accountName,
        },
      },
      currentArticleUuid,
      currentPageType,
      currentRoute: asPath,
      edition,
      environment,
      handleCheckIsFollowed,
      handleFollow,
      handleOpenLoginDialog,
      handleRegisterMixPanel,
      handleRemoveRouteQuery,
      handleRoutePush,
      handleTrackGA,
      handleTrackGA4,
      handleTrackGTM,
      handleTrackMixPanel,
      handleTrackOnePlusX,
      hasAcceptedGDPR,
      hasEnoughFollowedItems,
      hasWhyRegisterPPTOHomepagePositionIntersected,
      homeAsPath,
      initialHasEnoughFollowedItems,
      isShowSubscription,
      notification: { firebaseApp },
      rosettaInstance: asyncRosettaState?.result?.instance,
      user: user ?? undefined,
      userCountry: ipGeoLocation?.result?.country,
      userTypeIds,
    }),
    [
      asPath,
      asyncRosettaState?.result?.instance,
      bookmarkCheck,
      bookmarkUpdate,
      currentArticleUuid,
      currentPageType,
      edition,
      environment,
      firebaseApp,
      handleCheckIsFollowed,
      handleFollow,
      handleOpenLoginDialog,
      handleRegisterMixPanel,
      handleRemoveRouteQuery,
      handleRoutePush,
      handleTrackGA,
      handleTrackGA4,
      handleTrackGTM,
      handleTrackMixPanel,
      handleTrackOnePlusX,
      hasAcceptedGDPR,
      hasEnoughFollowedItems,
      hasWhyRegisterPPTOHomepagePositionIntersected,
      homeAsPath,
      initialHasEnoughFollowedItems,
      ipGeoLocation?.result?.country,
      isShowSubscription,
      user,
      userTypeIds,
    ],
  );

  return value;
};
