import { useFallbackStorageValue } from "@product/scmp-sdk";
import { useAsync, useMountEffect } from "@react-hookz/web";
import axios from "axios";

import { getGoogleAnalyticsClientId } from "~/components/tracking/google-analytics/apis";
import { config } from "~/data";

export type UserType =
  | "008"
  | "014"
  | "016"
  | "017"
  | "018"
  | "028"
  | "029"
  | "030"
  | "038"
  | "057"
  | "058"
  | "083"
  | "084"
  | "amp"
  | "default"
  | "myNews"
  | "newsletter"
  | "subscribers";

type FetchUserTypes = {
  id: UserType;
  name: string;
};

export type FetchUserTypeResponse = {
  data: {
    user_types: FetchUserTypes[];
  } | null;
};

export const useUserTypes = () => {
  const { value: overWrittenGAClientId } = useFallbackStorageValue<string>(
    "qaTestOverwriteGAClientId",
  );

  const [state, actions] = useAsync(async () => {
    try {
      const gaClientId =
        config.general.env !== "production" && overWrittenGAClientId
          ? overWrittenGAClientId
          : ((await getGoogleAnalyticsClientId()) as string);

      if (!gaClientId) return [];

      const url = new URL(`${config.general.userTypeHost}/client-id/${gaClientId}`);

      const response = await axios.get<FetchUserTypeResponse>(url.toString(), {
        withCredentials: true,
      });

      return response?.data.data?.user_types ?? [];
    } catch {
      return [];
    }
  });

  useMountEffect(() => {
    void actions.execute();
  });

  return { userTypes: state.result ?? [] };
};
